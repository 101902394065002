import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";

@Module
export default class User extends VuexModule {
    salesAgents = []
    allLeads = []

    // Actions
    @Action
    async ['GET_SALES_AGENTS'](payload) {
        try {
            const response = await ApiService.post('/misk-skye/master/agent-list-for-dom-new', payload) // Misk Route
            this.context.commit('SET_SALES_AGENTS', response.data.data)

            return response
        } catch (error) {
            return error
        }
    }

    // Mutations
    @Mutation
    ['SET_SALES_AGENTS'](data) {
        this.salesAgents = data.agents
        this.allLeads = data.all_leads
    }
}


import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import ApiService from "@/core/services/ApiService";
import IPromotionCodeList from "@/models/IPromotionCode";
import IPaginationInfo from "@/models/IPaginationInfo";
export interface PromotionCodeInfo {
    promotionCodeUrl: PromotionCodeUrlInfo;
    promotionCodeList: IPromotionCodeList;
    paginationObj: IPaginationInfo;
}

export interface PromotionCodeUrlInfo {
    getPromotionCodeList: string;
    getPromotionCodeTypes: string;
    addPromoCode:string;
    showPromoCode:string;
    updatePromoCode:string
}


@Module
export default class PromotionCodeModule extends VuexModule implements PromotionCodeInfo {
    promotionCodeUrl = {} as PromotionCodeUrlInfo;
    promotionCodeList = {} as IPromotionCodeList;
    paginationObj = {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: 1,
        total: 1,
    } as IPaginationInfo;
    promotionCodeTypes = {}; 
    editPromotionCodeObj = {};
    errors = [];
  
    @Action
    [Actions.GET_PROMOTION_CODE_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            this.promotionCodeUrl.getPromotionCodeList = (payload.index == 1) ? "/skye/promotions/list-codes" : "/skye/promotions/list-codes?page=" + payload.index;

            ApiService.post(this.promotionCodeUrl.getPromotionCodeList, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_PROMOTION_CODE_ERROR, []);
                    this.context.commit(Mutations.SET_PROMOTION_CODE_LIST, data.data.codes);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_PROMOTION_CODE_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    
    @Action
    [Actions.GET_PROMOTION_CODE_TYPES]() {// 
        return new Promise<void>((resolve, reject) => {
        this.promotionCodeUrl.getPromotionCodeTypes = "/skye/promotions/show-code-types";
        ApiService.post(this.promotionCodeUrl.getPromotionCodeTypes, {})
            .then(({ data }) => {  //
            this.context.commit(Mutations.SET_PROMOTION_CODE_TYPES, data.data);
            resolve(data);
            })
            .catch(({ response }) => { // 
            this.context.commit(Mutations.SET_PROMOTION_CODE_ERROR, response.data);
            reject(response);
            });
        })
    }

    @Action
    [Actions.ADD_PROMOTION_CODE](payload){
        return new Promise<void>((resolve, reject) => {
            this.promotionCodeUrl.addPromoCode = "/skye/promotions/add-code";
            ApiService.post(this.promotionCodeUrl.addPromoCode,payload)
            .then(({data}) => { 
               
                this.context.commit(Mutations.SET_PROMOTION_CODE_ERROR, []);
                resolve(data);
            })
            .catch(({ response }) => { 
                this.context.commit(Mutations.SET_PROMOTION_CODE_ERROR, response.data.data);
                reject(response);
            });
        })
    }

    @Action
    [Actions.FETCH_PROMOTION_CODE](payload) {
        return new Promise<void>((resolve, reject) => {
            this.promotionCodeUrl.showPromoCode = "/skye/promotions/show-code";
           
            ApiService.post(this.promotionCodeUrl.showPromoCode,payload)//,payload
            .then(({data}) => { 
               //  
                this.context.commit(Mutations.SET_PROMOTION_CODE,data.data.code);
                resolve(data);
               
            })
            .catch(({ response }) => {
                reject(response);
            });
        })
    }

    @Action
    [Actions.UPDATE_PROMOTION_CODE](payload){
        return new Promise<void>((resolve, reject) => {
            this.promotionCodeUrl.updatePromoCode = "/skye/promotions/update-code";
            ApiService.post(this.promotionCodeUrl.updatePromoCode,payload)
            .then(({data}) => { 
                this.context.commit(Mutations.SET_PROMOTION_CODE_ERROR, []);
                resolve(data);
            })
            .catch(({ response }) => { 
                this.context.commit(Mutations.SET_PROMOTION_CODE_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    

    @Mutation
    [Mutations.SET_PROMOTION_CODE_LIST](payload) {
       
        this.promotionCodeList = payload.data;
        this.paginationObj.links = payload.links;
        this.paginationObj.first_page_url = payload.first_page_url;
        this.paginationObj.last_page_url = payload.last_page_url;
        this.paginationObj.from = payload.from;
        this.paginationObj.to = payload.to;
        this.paginationObj.next_page_url = payload.next_page_url;
        this.paginationObj.prev_page_url = payload.prev_page_url;
        this.paginationObj.total = payload.total;
    }
    @Mutation
    [Mutations.SET_PROMOTION_CODE_ERROR](error) { 
        const errors = [] as  any;
        for (const key in error) {
      
          errors.push( error[key]);
         
        }
        this.errors = errors;
    }
    @Mutation
    [Mutations.SET_PROMOTION_CODE_TYPES](payload): void {
        this.promotionCodeTypes = payload;
    }
    @Mutation
    [Mutations.SET_PROMOTION_CODE](payload): void {
        this.editPromotionCodeObj = payload;
    }
    get getPromotionCode(): IPromotionCodeList {
        return this.promotionCodeList;
    }
    get getPromotionCodePaginationObj(): IPaginationInfo {
        return this.paginationObj;
    }

    get domPromotionCodeTypes() {
        return this.promotionCodeTypes;
    }

    get editPromotionCode() {
        return this.editPromotionCodeObj;
    }

}

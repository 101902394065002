import { useRoute } from "vue-router"
import { ref, watchEffect, h } from "vue"
import Master from "@/core/services/car/NEW/Master"
import Customer from "@/core/services/car/NEW/Customer"
import Quotes from "@/core/services/car/NEW/Quotes"
import User from "@/core/services/car/NEW/User"
// import QuotesService from "@/core/services/car/QuotesService"
// import UserService from "@/core/services/UserService"
import moment from "moment"
import { notif } from '@/store/stateless/store'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex"
import { Mutations } from "@/store/enums/StoreEnums";
import { ElDivider } from "element-plus";
import { pageTitle, setBreadCrumbs, setFromBreadCrumbs } from '@/store/composable/BreadCrumbs'
import { useRoleBasedConditions } from '@/store/composable/User'

export function useCustomerDetails(id = 0, clear = true) {
    const route = useRoute()
    const store = useStore()
    const { pCUstomerDetails } = useRoleBasedConditions()

    let customer_id = id
    let title = ''
    if(route){
        const route_name = route.name
        if(route_name && !pCUstomerDetails) window.location.href = '/#/404'
        
        customer_id = route.params.customerId
        title = pageTitle(route_name)
        let breadcrumb = setBreadCrumbs(route_name)
        let fromBreadcrumb = setFromBreadCrumbs(route_name)

        store.commit(Mutations.SET_LOADED_COMPONENT, false)
        setCurrentPageBreadcrumbs(breadcrumb, fromBreadcrumb)
    }


    watchEffect(async() => {
        
        await Customer.getCustomerDetailLeads({ customer_id, clear })

        // will be remove
        // if(response.data.data.leads.length > 0) {
        //     const driver = response.data.data.leads[0]
        //     await QuotesService.fetchQuote({driver_details_id: driver.lead_driver_detail.id})
        // }
        
        if(route) {
            store.commit(Mutations.SET_LOADED_COMPONENT, true)
        }
    })

    return {
        title,
        size: 30,
        spacer: h(ElDivider, { direction: "vertical" })
    }
}

export function useAmendInvoice() {
    const route = useRoute()
    // const store = useStore()

    const { pAmendInvoice } = useRoleBasedConditions()

    const route_name = route.name
    let invoice_id = 0
    if(route_name && !pAmendInvoice) window.location.href = '/#/404'

    invoice_id = route.params.invoiceId
    let title = pageTitle(route_name)
    let breadcrumb = setBreadCrumbs(route_name)
    let fromBreadcrumb = setFromBreadCrumbs(route_name)

    watchEffect(async() => {
        setCurrentPageBreadcrumbs(breadcrumb, fromBreadcrumb)
        await useFetchAmendInvoiceInformation({
            invoice_id,
            amend_type: 1
        })
        await Customer.getInsurancePlan({})
        await Master.getCountryCodes()
        await Quotes.getCountries()
        await Master.getPolicyStartDateDays()

    })

    return { title }
}

export function useFetchCustomer() {
    const route = useRoute()
    const store = useStore()
    const { pCUstomerDetails } = useRoleBasedConditions()


    const route_name = route.name
    if(route_name && !pCUstomerDetails) window.location.href = '/#/404'
    
    const driver_id = route.params.driverDetailsId
    let customer_id = 0
    const route_customer_id = route.params.customerId
    const leadsAndTasks = route.query.leadsAndTasks
    let componentLoad = store.state.Customer.componentLoad

    store.commit(Mutations.SET_LOADED_COMPONENT, false)

    let title = pageTitle(route_name)
    let breadcrumb = setBreadCrumbs(route_name)
    let fromBreadcrumb = setFromBreadCrumbs(route_name)
    
    Quotes.resetValues()
    watchEffect(async() => {
        let customer = store.state.Customer.customer
        

        setCurrentPageBreadcrumbs(breadcrumb, fromBreadcrumb)

        if(['new-car-quote', 'edit-quote', 'add-driver-details', 'edit-bike-quote', 'generate-driver-details-manaual-quote', 'generate-invoice'].includes(route_name)) {
            const response = await Quotes.fetchQuote({driver_details_id: driver_id})
            const setCustomerValues = setCustomerValue(customer, response.data.data.quote, route_name)
            store.commit(Mutations.SET_NEW_CUSTOMER, setCustomerValues)
            if(route_name !== 'new-car-quote') await preloadData(setCustomerValues)
            if(route_name == 'generate-invoice') await Customer.getInsurancePlan({ policy_for: setCustomerValues.vehicle_type })
           customer_id = setCustomerValues.customer_id
        } else if(['add-lead-driver-details', 'customer-add-new-bike', 'generate-manaual-quote'].includes(route_name)) {
            const response = await Customer.getCustomerDetailLeads({ customer_id: route_customer_id })
            const setCustomerValues = setCustomerValue(customer, response.data.data, route_name)
            store.commit(Mutations.SET_NEW_CUSTOMER, setCustomerValues)
            customer_id = setCustomerValues.customer_id
        } else if(route_name == 'customer-add-bike-detail') {
            store.commit(Mutations.SET_RESET_CUSTOMER, 2)
        } else {
            store.commit(Mutations.SET_RESET_CUSTOMER, 1)
        }
        
        componentLoad = true
        setTimeout(() => {
            store.commit(Mutations.SET_LOADED_COMPONENT, true)
        }, 200);

       await Master.getCountryCodes()
       await Quotes.getCountries()
       await Master.getPolicyStartDateDays()

       if(['generate-invoice', 'customer-add-detail', 'customer-add-bike-detail"'].includes(route_name)) {
            await User.getDomSalesAgents({
                type: 'Misk',
                isArchived: true,
                isActive: true
           })

       }
        

    })


    return { title, route_name, driver_id, customer_id, leadsAndTasks, componentLoad }
}

export const error = ref('')

export function useUpdateError(value) {
    error.value = value
}

export async function useGetCountryCodes() {
    await Master.getCountryCodes()
}

export async function useGetCustomerLogs({ customer_id, value }) {
    if(value === 2) await Customer.getCustomerStatusLogs({customer_id})
    else await Customer.getCustomerActivityLogs({customer_id})
}

export function useClearPhoneNumber(customer) {
    customer.phone_number = ''
    Customer.clearExistCustomer()
}

export async function useSaveMakeInvoice(customer) {
    const response = await Customer.makeInvoice(customer)
    if(response.status < 299) {
        notif.simple("Make Invoice", "success", "You have successfully initiated Make invoice!");
    } 
    return response
}

export async function useSaveButton(customer, name) {
    let response = null
    let toResponse = false

    if(!['edit-quote', 'edit-bike-quote'].includes(name)) delete customer.driver_details_id

    customer.linkType = name
    response = await Customer.withGlobal(customer)

    if(response.response) {
        if(response.response.data.message.includes('Duplicate')) {
            notif.simple("Please Note", "warning", response.response.data.message, 0);
        }
    } else {
        if(name == 'customer-add-detail') {
            // response = await CustomerService.addUserDetails(customer)
            toResponse = true
            notif.simple("Customer Added", "success", "Customer has been added successfully!");
        }
    
        if(name == 'edit-quote') {
            // response = await QuotesService.editQuote(customer)
            toResponse = false
            notif.simple("Customer Details Edited", "success", "You have successfully edited the customer details.");
        }
    
        if(['add-driver-details', 'new-car-quote', 'add-lead-driver-details'].includes(name)) {
            // response = await QuotesService.addQuote(customer)
            toResponse = false
            notif.simple("Customer", "success", "You have successfully added a new Lead!");
        }
    
        if(name == 'customer-add-bike-detail') {
            // response = await CustomerService.addBikeUserDetails(customer)
            toResponse = true
            notif.simple("Customer Added", "success", "Customer has been added successfully!");
        }
    
        if(name == 'customer-add-new-bike') {
            // response = await CustomerService.addBikeUserDetails(customer)
            toResponse = false
            notif.simple("Customer Added", "success", "Customer has been added successfully!");
        }
    
        if(name == 'edit-bike-quote') {
            // response = await CustomerService.EditBikeUserDetails(customer)
            toResponse = false
            notif.simple("Customer Edit", "success", "Customer has been updated successfully!");
        }

        if(['generate-manaual-quote', 'generate-driver-details-manaual-quote'].includes(name)) {
            toResponse = false
            notif.simple('Quotes Generated', 'success', 'Quotation has been generated successfully.');
        }
    
        if(!['customer-add-detail', 'customer-add-bike-detail'].includes(name)) {
            await Customer.getCustomerDetailLeads({ customer_id: customer.customer_id })
            // await QuotesService.fetchQuote({driver_details_id: res.data.data.leads[0].lead_driver_detail.id})
        }
    }
    


    return {
        response, toResponse
    }
}

export async function useCheckPhoneNumber({ phoneNo, country }) {
    return await Customer.checkExistNumber({
        phoneNo, country
    })
}

export async function useCarYears(customer, isClear = false) {
    resetValue(customer, isClear)
    Quotes.resetValues()

    await Master.getCarYears({ is_vintage: customer.is_vintage })
}

export async function useOnCarYear(customer) {
    resetValue(customer, true, false)
    Quotes.resetValues()

    const payload = {
        year: customer.car_year,
        is_vintage: customer.is_vintage,
        is_show_bike: customer.vehicle_type && customer.vehicle_type == 2 ? true : false
    }
    if(customer.vehicle_type === 1) await Quotes.getManufacturers(payload)
    if(customer.vehicle_type === 2) {
        const response = await Quotes.getBikeManufacturers(payload)
        const manu = response.data.data.manufacturers[0]
        customer.manufacturer_id = manu ? manu.make_id : 0
        customer.model_id = manu ? manu.model_id : 0
        await useOnModel(customer)
    }
}

export async function useOnManufacturer(customer) {
    resetValue(customer, true, false, false)
    Quotes.resetModelValues();

    const payload = {
        manufacturer_id: customer.manufacturer_id,
        year: customer.car_year,
        is_vintage: customer.is_vintage
    }

    await Quotes.getModels(payload)
}

export async function useOnModel(customer) {
    resetValue(customer, true, false, false, false)
    Quotes.resetTrimValues()

    const payload = {
        year: customer.car_year,
        model_id: customer.model_id,
        is_vintage: customer.is_vintage
    }

    await Quotes.getTrims(payload)
}

export async function useOnTrim(customer) {
    resetValue(customer, true, false, false, false, false)

    const payload = {
        trim_level_id: customer.trim_level_id,
        is_vintage: customer.is_vintage
    }

    const response = await Quotes.getEstimatedValue(payload)
    customer.car_value = customer.is_new ? response.data.data.value.new_min : response.data.data.value.old_min
}

export function useFixFigure(value) {
    let figures = roundUp(parseFloat(value.toString().replace(',', '')))
    if(isNaN(figures)) figures = 0
    return figures
}

export async function useUpdateCustomerData(params) {
    return await Customer.withGlobal(params)
}

export async function useViewDetails(customer) {
    await preloadData(customer)
    await Quotes.getCountries()
}

export async function useViewTasks({ customer_id, lead_id }) {
    await Customer.getAllTaskByLeadAndCustomerId({ customer_id, lead_id })
}

export async function useViewNotes({ lead_id }) {
    await Customer.getAllNotesByLeadId({ lead_id })
}

export async function useViewPolicies({ customer_id, lead_id }) {
    await Customer.getAllPoliciesByLeadAndCustomerId({ customer_id, lead_id })
}

export async function useViewLeadLogs({ customer_id, misk_lead_id }) {
    await Customer.getLeadLogs({ customer_id, misk_lead_id })
}

export async function useViewDocumentLog({ reference_id, module = 'policy' }) {
    await Customer.getDocumentLogList({reference_id, module})
}

export async function useViewPolicyLog({policy_purchase_id}) {
    await Customer.getPolicyLogList({policy_purchase_id})
}

export async function useViewEmails({customer_id}) {
    await Customer.getEmailList({customer_id})
}

export async function useViewManualQuotes({customer_id}) {
    await Customer.getManualQuotes({ customer_id })
}

export async function useViewSms({customer_id}) {
    await Customer.getSms({ customer_id })
}

export async function useFetchCustomerResponses({ lead_id, customer_id }) {
    return await Customer.getResponses({ lead_id, customer_id })
}

export async function useFetchAmendInvoiceInformation({ invoice_id, amend_type }) {
    const store = useStore()
    const response = await useUpdateCustomerData({
        linkType: 'amend-invoice',
        invoice_id,
        amend_type
    })
    if(store) store.commit('SET_AMEND_INVOICE', store.getters.ProduceAmendInvoice(response.data.data.invoice, amend_type))
    return response
}

function roundUp(num) {
    let p = Math.pow(10, 2);
    return Math.round(num * p) / p;
}

function resetValue(customer, isClear = false, isYearClear = true, isManufacturerClear = true, isModelClear = true, isTrimClear = true) {
    if(isClear) {
        if(isYearClear) {
            customer.car_year = 0
            customer.is_new = 2
            customer.first_registration_date = ''
        }
        if(isManufacturerClear) customer.manufacturer_id = 0
        if(isModelClear) customer.model_id = 0
        if(isTrimClear) customer.trim_level_id = 0
        customer.car_value = 0

        if(!customer.is_vintage && customer.car_year) customer.first_registration_date = moment().year(customer.car_year).format('YYYY-MM-DD')
    }
}

async function preloadData(customer) {
    const payload = {
        year: customer.car_year,
        is_show_bike: customer.vehicle_type && customer.vehicle_type == 2 ? true : false,
        manufacturer_id: customer.manufacturer_id,
        model_id: customer.model_id,
        trim_level_id: customer.trim_level_id,
        is_vintage: customer.is_vintage
    }

    await Quotes.getManufacturers(payload)
    await Quotes.getModels(payload)
    await Quotes.getTrims(payload)
    await Quotes.getEstimatedValue(payload)

}

function setCustomerValue(customer, response, route_name) {
    if(route_name == 'new-car-quote') {
        response.lead.car_year = 0
        response.lead.manufacturer_id = 0
        response.lead.model_id = 0
        response.lead.trim_level_id = 0
        response.lead.is_new = 2
        response.car_value = 0
        response.first_registration_date = ''
        response.lead.registration_emirate = 2
        response.policy_start_date = moment().format('YYYY-MM-DD')
        response.lead.gcc_specification = 1
        response.personal_use = 1
        response.current_policy_active = 1
        response.fully_comprehensive = 1
        response.third_party_liability = 2
        response.existing_policy_expired = 2

    }
    if(route_name == 'add-driver-details') {
        response.dob = ''
        response.nationality = null
        response.first_driving_license_country = null
        response.driving_experience = 4
        response.uae_driving_experience = 4
        response.claimed_insurance = 2
        response.no_claim_certificate = 1
        response.claims = null
        response.gender = null
        response.driver_name = null
    }

    if(['add-lead-driver-details', 'customer-add-new-bike', 'generate-manaual-quote'].includes(route_name)) return setAddNewField(customer, response, route_name)
    if(['edit-quote', 'edit-bike-quote', 'new-car-quote', 'add-driver-details', 'generate-driver-details-manaual-quote', 'generate-invoice'].includes(route_name)) return setEditQuoteFields(customer, response, route_name)

}

function setEditQuoteFields(customer, response, route_name) {
        customer.name = response.customer.name
        customer.email = response.customer.email
        customer.phone_country_code = response.customer.phone_country_code
        customer.phone_number = response.customer.phone_number_without_code
        customer.car_year = parseInt(response.lead.car_year)
        customer.manufacturer_id = response.lead.manufacturer_id
        customer.model_id = response.lead.model_id
        customer.trim_level_id = response.lead.trim_level_id
        customer.is_new = response.lead.is_new || 2
        customer.car_value = parseFloat(response.car_value)
        customer.first_registration_date = response.lead.first_registration_date || moment().year(parseInt(response.lead.car_year)).format('YYYY-MM-DD')
        customer.registration_emirate = response.lead.registration_emirate || 2
        customer.dob = response.dob
        customer.nationality = response.nationality
        customer.first_driving_license_country = response.first_driving_license_country
        customer.driving_experience = response.driving_experience || 4
        customer.uae_driving_experience = response.uae_driving_experience || 4
        customer.policy_start_date = response.policy_start_date ? response.policy_start_date : moment().format('YYYY-MM-DD')
        customer.claimed_insurance = response.claimed_insurance || 2
        customer.no_claim_certificate = parseInt(response.no_claim_certificate) || 1
        customer.claims = response.claims
        customer.gcc_specification = response.lead.gcc_specification || 1
        customer.personal_use = response.personal_use || 1
        customer.current_policy_active = response.current_policy_active || 1
        customer.fully_comprehensive = response.fully_comprehensive || 1
        customer.third_party_liability = response.third_party_liability || 2
        customer.existing_policy_expired = response.existing_policy_expired || 2
        customer.driver_name = response.driver_name
        customer.agent = response.lead.agent
        customer.gender = response.gender
        customer.is_car = 1
        customer.lead_source = 'skye'
        customer.is_vintage = response.lead.is_vintage
        customer.vehicle_type = ['edit-bike-quote', 'customer-add-new-bike', 'customer-add-bike-detail'].includes(route_name) ? 2 : 1
        customer.customer_id = response.customer_id
        customer.lead_id = response.lead_id,
        customer.driver_details_id = response.id
        customer.policy_type = 0
        customer.dummy_email = response.customer.email
        if(route_name == 'generate-invoice') {
            customer.policy_sales_agent_id = response.lead.agent
            customer.bai_car_insurance_policy_id = 0
            customer.is_agency_repair = null
            customer.original_price = 0
            customer.sale_price = 0
            customer.is_discount_given = 0
            customer.discount_amount = 0
            customer.payment_type = 0
            customer.intransact_policy_id = 0
            customer.order_description = ''
            customer.reference_no = ''
            customer.expiry_date_time = ''
            customer.payment_date = ''
            customer.language = 'en'
            customer.invoice_doc = ''
            customer.lead_status = response.lead.lead_status_id
        }

    return customer
}

function setAddNewField(customer, response, route_name) {
    customer.name = response.customer.name
    customer.email = response.customer.email
    customer.phone_country_code = response.customer.phone_country_code
    customer.phone_number = response.customer.phone_number_without_code
    customer.car_year = 0
    customer.manufacturer_id = 0
    customer.model_id = 0
    customer.trim_level_id = 0
    customer.is_new = 2
    customer.car_value = 0
    customer.first_registration_date = ''
    customer.registration_emirate = 2
    customer.dob = ''
    customer.nationality = null
    customer.first_driving_license_country = null
    customer.driving_experience = 4
    customer.uae_driving_experience = 4
    customer.policy_start_date = moment().format('YYYY-MM-DD')
    customer.claimed_insurance = 2
    customer.no_claim_certificate = 1
    customer.claims = null
    customer.gcc_specification = 1
    customer.personal_use = 1
    customer.current_policy_active = 1
    customer.fully_comprehensive = 1
    customer.third_party_liability = 2
    customer.existing_policy_expired = 2
    customer.driver_name = ['generate-manaual-quote', 'generate-driver-details-manaual-quote'].includes(route_name) ? '' :response.customer.name
    customer.agent = response.customer.agent
    customer.gender = null
    customer.is_car = 1
    customer.lead_source = 'skye'
    customer.is_vintage = 0
    customer.vehicle_type = ['edit-bike-quote', 'customer-add-new-bike', 'customer-add-bike-detail'].includes(route_name) ? 2 : 1
    customer.customer_id = response.customer.id
    customer.lead_id = response.leads[0].id,
    customer.driver_details_id = response.leads[0].lead_driver_detail.id
    customer.policy_type = 0
    customer.dummy_email = response.customer.email

    if(['generate-manaual-quote', 'generate-driver-details-manaual-quote'].includes(route_name)) {
        customer.policy_data = []
        customer.companies = []
    }

    return customer
}
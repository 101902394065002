import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import ILeadActivyLog from "@/models/car/ILeadActivyLog";
import ILeadStatusLog from "@/models/car/ILeadStatusLog";

import axios from "axios";

export interface LeadsInfo {
    leadsUrl:LeadsUrlInfo;
    leadActivityLogList:ILeadActivyLog;
    leadStatusLogList:ILeadStatusLog;
    errors:Array<string>;
    leadToMerge:ILeadToMerge;
  }

export interface LeadsUrlInfo{
  getLeadActivityUrl:string;
  getLeadStatusUrl:string;
  checkLeadMergeUrl:string;
  leadMergeUrl:string;
  updateClosedLead:string;
  getCustomerLeadsUrl:string;
}

interface ILeadToMerge{
    is_active:boolean;
    lead_id:number;
}



@Module
export default class LeadModule extends VuexModule implements  LeadsInfo{

    leadsUrl = {} as LeadsUrlInfo;
    leadActivityLogList = {} as ILeadActivyLog;
    leadStatusLogList = {} as ILeadStatusLog;
    leadToMerge = {} as ILeadToMerge;
    leads = [];
    errors = [];
    
    @Action
    [Actions.GET_LEAD_ACTIVITY_LOG](payload){ 
        return new Promise<any>((resolve, reject) => {
            this.leadsUrl.getLeadActivityUrl = "/misk-skye/leads/list-activity-logs";
            ApiService.post(this.leadsUrl.getLeadActivityUrl,payload)
            .then(({data}) => {  
                this.context.commit(Mutations.SET_LEAD_ACTIVITY_LOG,data.data.logs);
                resolve(data);
            })
            .catch(({ response }) => {  
                this.context.commit(Mutations.SET_LEADS_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    @Action
    [Actions.GET_LEAD_STATUS_LOG](payload){ 
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_LEAD_LOG_DOM_STATUS, false); 
            this.leadsUrl.getLeadStatusUrl = "/misk-skye/leads/list-status-logs";
            ApiService.post(this.leadsUrl.getLeadStatusUrl,payload)
            .then(({data}) => {  
                this.context.commit(Mutations.SET_LEAD_STATUS_LOG,data.data.logs);
                resolve(data);
                this.context.commit(Mutations.SET_LEAD_LOG_DOM_STATUS, true); 
            })
            .catch(({ response }) => {  
                this.context.commit(Mutations.SET_LEADS_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    @Action
    [Actions.CHECK_LEAD_MERGE](payload){ 
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_LEAD_LIST_DOM_STATUS, false); 
            this.leadsUrl.checkLeadMergeUrl = "skye/car-leads/check-scenario-eight";
            ApiService.post(this.leadsUrl.checkLeadMergeUrl,payload)
            .then(({data}) => {  
                this.context.commit(Mutations.SET_LEAD_MERGE_STATUS,data.data);
                resolve(data);
                this.context.commit(Mutations.SET_LEAD_LIST_DOM_STATUS, true); 
            })
            .catch(({ response }) => {  
                this.context.commit(Mutations.SET_LEADS_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    @Action
    [Actions.MERGE_LEAD](payload){ 
        return new Promise<any>((resolve, reject) => {
            // this.context.commit(Mutations.SET_LEAD_LIST_DOM_STATUS, false); 
            this.leadsUrl.leadMergeUrl = "skye/car-leads/merge-new-car-lead";
            ApiService.post(this.leadsUrl.leadMergeUrl,payload)
            .then(({data}) => {  
                // this.context.commit(Mutations.SET_LEAD_MERGE_STATUS,data.data);
                resolve(data);
                // this.context.commit(Mutations.SET_LEAD_LIST_DOM_STATUS, true); 
            })
            .catch(({ response }) => {  
                this.context.commit(Mutations.SET_LEADS_ERROR, response.data.data);
                reject(response);
            });
        })
    }

    @Action
    [Actions.UPDATE_CLOSED_LEAD](payload){ 
        return new Promise<any>((resolve, reject) => {
            this.leadsUrl.updateClosedLead = "skye/car-leads/update-closed-lead";
            ApiService.post(this.leadsUrl.updateClosedLead,payload)
            .then(({data}) => {  
                resolve(data);
          
            })
            .catch(({ response }) => {  
                this.context.commit(Mutations.SET_LEADS_ERROR, response.data.data);
                reject(response);
            });
        })
    }

    @Action
    [Actions.GET_CUSTOMER_LEADS_FOR_MERGE](payload){ 
        return new Promise<any>((resolve, reject) => {
            this.leadsUrl.getCustomerLeadsUrl = "/skye/master/get-customer-leads-for-merge";
            ApiService.post(this.leadsUrl.getCustomerLeadsUrl,payload)
            .then(({data}) => {  
                this.context.commit(Mutations.SET_CUSTOMER_LEADS_FOR_MERGE,data.data.leads);
                resolve(data);
            })
            .catch(({ response }) => {  
                this.context.commit(Mutations.SET_LEADS_ERROR, response.data.data);
                reject(response);
            });
        })
    }



    @Mutation
    [Mutations.SET_LEADS_ERROR](error) { 
        for (const key in error) {
            this.errors = error[key];
          }
    }
   
    @Mutation
    [Mutations.SET_LEAD_MERGE_STATUS](payload) : void {
      this.leadToMerge = payload;
    }

    @Mutation
    [Mutations.SET_LEAD_ACTIVITY_LOG](payload) : void {
      this.leadActivityLogList = payload;
    }

    @Mutation
    [Mutations.SET_LEAD_STATUS_LOG](payload) : void {
      this.leadStatusLogList = payload;
    }

    @Mutation
    [Mutations.SET_CUSTOMER_LEADS_FOR_MERGE](payload) : void {
      this.leads = payload;
    }
    
   
    get getLeadToMergeStatus(): ILeadToMerge{
        return  this.leadToMerge;
    }

    get getLeadsForMerge() {
        return this.leads;
    }
}

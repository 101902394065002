
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ICustomers from "@/models/Customers";
import ApiService from "@/core/services/ApiService";
import ILeads from "@/models/car/ILeads";
import IEvents from "@/models/Events";
import ICustomerActivityLog from "@/models/car/ICustomerActivityLog";
import ICustomerStatusLog from "@/models/car/ICustomerStatusLog";
import ICustomerSearchResult from "@/models/car/ICustomerSearchResult";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { notif } from '@/store/stateless/store';
export interface CustomerInfo {
  customers: ICustomers;
  paginationObj: CustomerPaginationInfo;
  customerUrl: CustomerUrlInfo;
  customerDetails: ICustomers;
  statusCount: CustomerStatusCountInfo;
  leadStatusCount: CustomerStatusCountInfo;
  salesAgents: ISalesAgent;
  teamMembers: ISalesAgent;
  leadDetails: ILeads;
  eventDetails: IEvents;
  customerStatusLogList: ICustomerStatusLog;
  customerSearchList: ICustomerSearchResult;
  errors: Array<string>;
  duplicateData: IDuplicateData;
}

export interface IDuplicateData {
  status: boolean;
  message: string;
}
export interface CustomerPaginationInfo {
  links: Array<any>;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string;
  from: number;
  to: number;
  total: number;
}
export interface CustomerStatusCountInfo {
  status: number;
  value: string;
  count: number;
  hasData: boolean;
} 
export interface CustomerUrlInfo {
  listCustomer: string;
  getCustomerDetails: string;
  getCustomerStatusCount: string;
  getSalesAgentList: string;
  getDueDateTaskCount: string;
  getFileteredDueDateTaskCount: string;
  transferLeads: string;
  importCustomerList: string;
  exportCustomerList: string;
  getCalendarDetails: string;
  eventListDetails: string;
  addLeadTask: string;
  mergeQuickLeadUrl: string;
  check_number_exist: string;
  getActivityLog: string;
  getStatusLog: string;
  searchCustomer: string;
  addDetailUser: string;
  updateCustomerEmail:string;
  updateCustomerPhone: string;
  updateOtherContactInfo:string;
}

export interface TaskDueDateCountInfo {
  today: number;
  yesterday: number;
  last_30_days: number;
  older_than_30_days: number,
  tomorrow: number,
  upcoming: number,
  all: number,
  hasData: boolean;
}

export interface ISalesAgent {
  value: string;
  label: string;
  email: string;
}

@Module
export default class CustomerModule extends VuexModule implements CustomerInfo {
  customers = {} as ICustomers;
  paginationObj = {
    current_page: 1,
    data: [],
    first_page_url: "",
    from: 1,
    last_page: 1,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: "",
    prev_page_url: "",
    to: 1,
    total: 1,
  } as CustomerPaginationInfo;
  customerUrl = {} as CustomerUrlInfo;
  customerDetails = {} as ICustomers;
  statusCount = {} as CustomerStatusCountInfo;
  leadStatusCount = {} as CustomerStatusCountInfo;
  taskDueDateCount = {} as TaskDueDateCountInfo;
  taskFilteredDueDateCount = {} as TaskDueDateCountInfo;
  salesAgents = {} as ISalesAgent;
  teamMembers = {} as ISalesAgent;
  leadDetails = {} as ILeads;
  eventDetails = {} as IEvents;
  customerActivityLogList = [];
  customerViewActivityLogList = [];
  customerStatusLogList = {} as ICustomerStatusLog;
  customerSearchList = {} as ICustomerSearchResult
  duplicateData = {} as IDuplicateData;
  errors = [];
  QLerrors: any;
  otp_loading = false;
  otp_token = null;
  otp_response = null;
  otp_error = null;
  existCustomer = null;
  leadLoading = true;
  uploadTabLeadId = 0;
  phoneNumberModal = false;
  qlEmail = false
  addCustomerEmail = false
  updateEmail = false
  isEmailError = false
  isPhoneError = false;
  isQLDisable = true


  //     customers = [{
  //       id: 1,
  //       name:"borer",
  //       email: "borer.aditya@example.org",
  //       phone_number: 1-838-564-7179,
  //       agent: 2,
  //       last_visited_at: "1990-11-14 11:58:45",
  //       current_visited_at: "1990-11-14 11:58:45",
  //       created_at: "2021-09-29T06:27:54.000000Z",
  //       updated_at: "2021-09-30T11:58:00.000000Z",
  //       task_count: 0,
  //       hot_lead: 0,
  //       agent_details: {
  //           id: 2,
  //           name: "ashwin"
  //       }
  //  }];
  //  customers :any;
  //  customer = {} as ICustomers;

  @Action
  async [Actions.UPDATE_CUSTOMER_NAME](payload) {
    try {
      const data = await ApiService.post('/skye/customer/update-customer-name', payload)
      
      return data
    } catch (err) {
      return err
    }
  }

  @Action
  [Actions.LIST_CUSTOMER](payload) {
    this.context.commit(Mutations.SET_LEAD_LOADING, payload.reload);

    if(payload.reload) this.context.commit(Mutations.SET_DOM_STATUS, false);
    return new Promise<any>((resolve, reject) => {
      delete payload.reload
      this.customerUrl.listCustomer = (payload.index == 1) ? "/skye/customer-list" : "/skye/customer-list?page=" + payload.index;
      // if(index == 1){

      // }
      console.log(this.customerUrl.listCustomer);

      ApiService.post(this.customerUrl.listCustomer, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER, data);
          resolve(data);
          this.context.commit(Mutations.SET_DOM_STATUS, true);
          this.context.commit(Mutations.SET_LEAD_LOADING, false);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }
  @Action
  [Actions.ADD_QUICK_CUSTOMER](payload) {
    this.context.commit(Mutations.SET_QL_STATUS, false)

    return new Promise<any>((resolve, reject) => {
      ApiService.post("/skye/car-quick-lead", payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_CUSTOMER, data);
          resolve(data);
        })
        .catch((err) => { 
          // notif.Error();
          // console.log(err.response.data)
          if(err.response.status === 422) {
            if(err.response.data.message === true) {
              this.context.commit(Mutations.SET_QL_STATUS, true)
            }
          }
          reject(err);
          // this.context.commit(Mutations.SET_QUICK_LEAD_ERROR, response.data.data);
        });
    });
  }
  @Action
  [Actions.GET_CUSTOMER_DETAILS](payload,reloadDom=true) {
    const defData = {
      data: {
        customer: {},
        leads: []
      }
    }
    this.context.commit(Mutations.SET_CUSTOMER_DETAILS, defData);
    this.context.commit(Mutations.SET_LEAD_DETAILS, defData);
    if(reloadDom)
    this.context.commit(Mutations.SET_DOM_STATUS, false);

    this.customerUrl.getCustomerDetails = "/skye/car-leads";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getCustomerDetails, payload)
        .then(({ data }) => {
          // console.log({"datas": data});
          // console.log({ car_leads: data });
          this.context.commit(Mutations.SET_CUSTOMER_DETAILS, data);
          this.context.commit(Mutations.SET_LEAD_DETAILS, data);
          if (reloadDom)
          this.context.commit(Mutations.SET_DOM_STATUS, true);

          resolve(data);
        })
        .catch((err) => {
          // notif.Error();
          this.context.commit(Mutations.SET_DOM_STATUS, true);
          // this.context.commit(Mutations.SET_ERROR, err.response.data.errors);
          reject(err);
        });
    });
  }
  @Action
  [Actions.GET_CUSTOMER_DETAILS2](payload,reloadDom=true) {
    const defData = {
      data: {
        customer: {},
        leads: []
      }
    }
    // this.context.commit(Mutations.SET_CUSTOMER_DETAILS, defData);
    // this.context.commit(Mutations.SET_LEAD_DETAILS, defData);

    this.customerUrl.getCustomerDetails = "/skye/car-leads";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getCustomerDetails, payload)
        .then(({ data }) => {
          // console.log({"datas": data});
          // console.log({ car_leads: data });
          this.context.commit(Mutations.SET_CUSTOMER_DETAILS, data);
          this.context.commit(Mutations.SET_LEAD_DETAILS, data);

          resolve(data);
        })
        .catch((err) => {
          // notif.Error();
          // this.context.commit(Mutations.SET_ERROR, err.response.data.errors);
          reject(err);
        });
    });
  }

  @Action
  [Actions.GET_CUSTOMER_STATUS_COUNT](payload) {
    this.customerUrl.getCustomerStatusCount = "/skye/customer-list-count";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getCustomerStatusCount, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER_STATUS_COUNT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // reject();
        });
    });
  }
  @Action
  [Actions.GET_LEAD_STATUS_COUNT_TOOLBAR](payload) {
    this.customerUrl.getCustomerStatusCount = "/skye/customer-list-count";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getCustomerStatusCount, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LEAD_STATUS_COUNT_TOOLBAR, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // reject();
        });
    });
  }
  @Action
  [Actions.GET_LEAD_STATUS_FILTER_COUNT](payload) {
    this.customerUrl.getCustomerStatusCount = "/skye/customer-car-lead-count";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getCustomerStatusCount, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LEAD_STATUS_FILTER_COUNT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // reject();
        });
    });
  }
  @Action
  [Actions.GET_SALES_AGENT]() {
    this.customerUrl.getSalesAgentList = "/misk-skye/master/agent-list-for-dom-new";  // Misk Route
    let getPayload: any;
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getSalesAgentList, getPayload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SALES_AGENT_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          if(ApiService.errUndefinedCheck(response)){
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        }
      });
    });
  }
  @Action
  [Actions.GET_DUE_DATE_TASK_COUNT](payload) {
    this.customerUrl.getDueDateTaskCount = "/misk-skye/tasks/lt-open-task-counts";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getDueDateTaskCount, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TASK_DUE_DATE_COUNT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // reject();
        });
    });
  }
  @Action
  [Actions.GET_FILTERED_DUE_DATE_TASK_COUNT](payload) {
    this.customerUrl.getFileteredDueDateTaskCount = "/misk-skye/leads/filtered-task-due-date-count"; //MiskRoute
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getFileteredDueDateTaskCount, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FILTERED_TASK_DUE_DATE_COUNT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          if(ApiService.errUndefinedCheck(response)){
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          }
        });
    });
  }
  @Action
  [Actions.TRANSFER_LEADS](payload) {
    this.customerUrl.transferLeads = "/skye/car-transfer-lead";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.transferLeads, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_TASK_DUE_DATE_COUNT, data);
          notif.simple('Lead Transferred', 'success', "Customer/s will be transferred shortly. You will receive a notification once all customer/s are transferred.");
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          if(error.response.status === 422)
          {
            let data = '<ul>';

            Object.entries(error.response.data.data).forEach(element => {
                data += '<li style="color:red;">' + element[1] + '</li>'
            });
            
            data += '</ul>';
            //'Transfer Lead'  
            notif.simple('Lead Transfer', 'warning', error.response.data.message);
          }
          if(error.response.status === 400)
          {
            notif.error('Lead Transfer','error', error.response.data.message)
          }
          // notif.Error();
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // reject();
        });
    });
  }
  @Action
  [Actions.EXPORT_CUSTOMER_LIST](payload) {
    this.customerUrl.exportCustomerList = "/skye/customer-list-export";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.exportCustomerList, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_TASK_DUE_DATE_COUNT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // notif.Error();
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
  @Action
  [Actions.IMPORT_CUSTOMER_LIST](payload) {
    console.log(payload);
    this.customerUrl.importCustomerList = "skye/customer-list-import"
    return new Promise<any>((resolve, reject) => {
      this.context.commit(Mutations.SET_DISABLE_BUTTON, true);
      axios.post(this.customerUrl.importCustomerList,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          resolve(data);
        })
        .catch(({ response }) => {
          // notif.Error();
          this.context.commit(Mutations.SET_DISABLE_BUTTON, false);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
  @Action
  [Actions.GET_CALENDAR_DETAILS]() {
    this.customerUrl.eventListDetails = "/skye/task/calendar-view";
    let payload: any;
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.eventListDetails, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CALENDAR_EVENT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // notif.Error();
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }


  @Action
  [Actions.MERGE_QUICK_LEAD](payload) {
    this.customerUrl.mergeQuickLeadUrl = "/skye/merge-quick-car-lead";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.mergeQuickLeadUrl, payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_CALENDAR_EVENT, data);
          this.context.commit(Mutations.SET_ERROR, []);
          resolve(data);
        })
        .catch(({ response }) => {
          // notif.Error();
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });

  }


  @Action
  async [Actions.CHECK_EXIST_CUSTOMER](payload) {
    this.context.commit(Mutations.SET_QL_ENABLE, true)
    this.customerUrl.check_number_exist = "/skye/check-number-exist";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.check_number_exist, payload)
        .then((result) => {
          console.log({result});
          // this.context.commit(Mutations.SET_CALENDAR_EVENT, data);
          if(!result.data) {
            this.context.commit(Mutations.SET_QL_ENABLE, false)
          }
          this.context.commit(Mutations.SET_EXIST_CUSTOMER, result.data);
          resolve(result);
        })
        .catch(({ response }) => {
          // notif.Error();
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });

    // try {
    //   const result = await ApiService.post("/skye/check-number-exist", payload)
    // } catch (error) {
    //   return error
    // }


  }

  @Action
  [Actions.GET_CUSTOMER_ACTIVITY_LOGS](payload) {
    this.customerUrl.getActivityLog = "/misk-skye/customer/list-activity-logs";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getActivityLog, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER_ACTIVITIES, data.data.logs);
          this.context.commit(Mutations.SET_CUSTOMER_VIEW_ACTIVITIES, data.data.logs_viewed);
          resolve(data);
        })
        .catch(({ response }) => {
          // notif.Error();
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CUSTOMER_STATUS_LOGS](payload) {
    this.customerUrl.getStatusLog = "/misk-skye/customer/list-status-logs";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.getStatusLog, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER_STATUS, data.data.logs);
          this.context.commit(Mutations.SET_ERROR, []);
          resolve(data);
        })
        .catch(({ response }) => {
          // notif.Error();
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
  @Action
  [Actions.SEARCH_CUSTOMERS](payload) {

    this.customerUrl.searchCustomer = "/skye/customer/search-customer";
    return new Promise<any>((resolve, reject) => {
      ApiService.post(this.customerUrl.searchCustomer, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER_SEARCH_RESULT, data.data.customers);
          this.context.commit(Mutations.SET_ERROR, []);
          resolve(data);
        })
        .catch(({ response }) => {
          // notif.Error();
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
  @Action
  [Actions.ADD_USER_DETAILS](payload) { 

    this.customerUrl.addDetailUser = "/skye/car-detailed-lead";
    return new Promise<any>((resolve, rej) => {
      ApiService.post(this.customerUrl.addDetailUser, payload)
        .then(({ data }) => {
          // router.push({ name: "customer-details", params: { customerId: data.data.customer_id } });
          // this.context.commit(Mutations.SET_CUSTOMER_SEARCH_RESULT, data.data.customers);
          // this.context.commit(Mutations.SET_ERROR, []);
          resolve(data);
        })
        .catch((error) => {
          console.log(error.response)
          if(error.response.status === 422) {
            if(error.response.data.message === true) {
              this.context.commit(Mutations.SET_EMAIL_ADD_STATUS, true)
            }
          }
          // this.context.commit(Mutations.SET_ERROR, error.response.data.data);
          const serverError = error.response.data.message;
          console.log({serverError: serverError})
          if(serverError.includes('22003')) {
              notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
          }

          rej(error)

          let data = '<ul>';

          Object.entries(error.response.data.data).forEach(element => {
              data += '<li style="color:red;">' + element[1] + '</li>'
          });
          
          data += '</ul>';

          notif.simple('Customer ' + error.response.data.message, 'warning', data, 0)
          
        });
    });
  }

  @Action
  [Actions.ADD_BIKE_USER_DETAILS](payload) { 

    this.customerUrl.addDetailUser = "/skye/bike-detailed-lead";
    return new Promise<any>((resolve, rej) => {
      ApiService.post(this.customerUrl.addDetailUser, payload)
        .then(({ data }) => {
          // router.push({ name: "customer-details", params: { customerId: data.data.customer_id } });
          // this.context.commit(Mutations.SET_CUSTOMER_SEARCH_RESULT, data.data.customers);
          // this.context.commit(Mutations.SET_ERROR, []);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response)

          if (response.data.message == 'Duplicate entry for Bike and Driver details. This cannot be saved. Kindly refer to the existing record for further processing.') {
              notif.simple("Please Note", "warning", response.data.message, 0);
              this.duplicateData.message = response.data.message;
              this.duplicateData.status = true;
          }
          if(response.status === 422 && this.duplicateData && !this.duplicateData.status) {
            if(response.data.message === true) {
              this.context.commit(Mutations.SET_EMAIL_ADD_STATUS, true)
            }
          }
          // this.context.commit(Mutations.SET_ERROR, error.response.data.data);
          const serverError = response.data.message;
          console.log({serverError: serverError})
          // if(serverError.includes('22003')) {
          //     notif.simple("Please Note", "warning", 'Incorrect amount field entered.', 0);
          // }

          rej(response)

          // let data = '<ul>';

          // Object.entries(error.response.data.data).forEach(element => {
          //     data += '<li style="color:red;">' + element[1] + '</li>'
          // });
          
          // data += '</ul>';

          // notif.simple('Customer ' + error.response.data.message, 'warning', data, 0)
          
        });
    });
  }
  @Action
  [Actions.UPDATE_CUSTOMER_EMAIL](payload) {
    this.context.commit(Mutations.SET_EMAIL_IS_ERROR, false)

    this.customerUrl.updateCustomerEmail = "/skye/customer/update-customer-email";
    return new Promise((resolve, reject) => {
      
      this.context.commit(Mutations.SET_CUSTOMER_EMAIL_DISABLE_BUTTON, true);
      ApiService.post(this.customerUrl.updateCustomerEmail, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERRORS, null);
          
          this.context.commit(Mutations.SET_CUSTOMER_EMAIL_DISABLE_BUTTON, false);
          const customerDetails = this.customerDetails;
        
          if(Object.keys(customerDetails).length == 0)
          {
            this.context.commit(Mutations.UPDATE_EDIT_QUOTE_EMAIL, data.data);
          }
          if(Object.keys(customerDetails).length > 0)
          {
            console.log('Mutations.UPDATE_EDIT_CUSTOMER_EMAIL')
            this.context.commit(Mutations.UPDATE_EDIT_CUSTOMER_EMAIL, data.data);
          }
          notif.simple("Email updated", "success", "Email has been updated successfully!");
          // this.context.commit(Mutations.SET_ERROR, []);
          resolve(data);
        })
        .catch((error) => {
          // reject(error);
          // if(error.response.status === 422) {
          //   if(error.response.data.message === true) {
          //     this.context.commit(Mutations.SET_EMAIL_UPDATE_STATUS, true)
          //   }
          // }

          this.context.commit(Mutations.SET_CUSTOMER_EMAIL_DISABLE_BUTTON, false);
          if(error.response.status === 422)
          {
              // let data = '<ul>';

              // Object.entries(error.response.data.data).forEach(element => {
              //     data += '<li style="color:orange;">' + element[1] + '</li>'
              // });
              
              // data += '</ul>';

              // notif.simple(error.response.data.message, 'warning', data, 0)
              this.context.commit(Mutations.SET_EMAIL_IS_ERROR, true)
          }
          reject(error)
        });
    });
  }
  @Action
  [Actions.UPDATE_CUSTOMER_PHONE](payload) {

    this.customerUrl.updateCustomerPhone = "/skye/customer/update-customer-phone";
    return new Promise<any>((resolve, reject) => {
      
      // this.context.commit(Mutations.SET_CUSTOMER_EMAIL_DISABLE_BUTTON, true);
      ApiService.post(this.customerUrl.updateCustomerPhone, payload)
        .then(({ data }) => {
          notif.simple('Phone Number Updated', 'success', 'Phone number updated successfully!')
          // this.context.commit(Mutations.SET_CUSTOMER_EMAIL_DISABLE_BUTTON, false);
          // const customerDetails = this.customerDetails;
        
          // if(Object.keys(customerDetails).length == 0)
          // {
          //   this.context.commit(Mutations.UPDATE_EDIT_QUOTE_PHONE, data.data);
          // }
          // if(Object.keys(customerDetails).length > 0)
          // {
          //   console.log('Mutations.UPDATE_EDIT_CUSTOMER_EMAIL')
          //   this.context.commit(Mutations.UPDATE_EDIT_CUSTOMER_EMAIL, data.data);
          // }
          // this.context.commit(Mutations.SET_ERROR, []);
          
          resolve(data);
        })
        .catch((error ) => {
          
          if(error.response.status === 422)
          {
              // let data = '<ul>';

              // Object.entries(error.response.data.data).forEach(element => {
              //     data += '<li style="color:orange;">' + element[1] + '</li>'
              // });
              
              // data += '</ul>';

              // notif.simple(error.response.data.message, 'warning', data, 0)
              this.context.commit(Mutations.SET_PHONE_IS_ERROR, true)
          }
          //notif.simple("Please Note", 'warning', error.response.data.message, 0)
          reject(error);
        });
    });
  }

  @Action
  [Actions.SEND_OTP](payload){

    this.customerUrl.updateCustomerPhone = "/skye/verification/send-otp";
    return new Promise<any>((resolve, reject) => {
      

      this.context.commit(Mutations.SET_OTP_ERROR, null);
      this.context.commit(Mutations.SET_OTP_LOADING, true);
      ApiService.post(this.customerUrl.updateCustomerPhone, payload)
        .then(({ data }) => {
          console.log(data)
          this.context.commit(Mutations.SET_OTP_LOADING, false);
          this.context.commit(Mutations.SET_OTP_TOKEN, data.data.token);
          
          resolve(data);
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_OTP_LOADING, false);
          let data = '<ul>';

          Object.entries(error.response.data.data).forEach(element => {
              data += '<li style="color:red;">' + element[1] + '</li>'
          });
          
          data += '</ul>';
          this.context.commit(Mutations.SET_OTP_ERROR, data);
          reject(error);
        });
    });
  }

  @Action
  [Actions.VERIFY_OTP_AND_UPDATE_PHONE_NUMBER](payload){

    this.customerUrl.updateCustomerPhone = "/skye/verification/verify-otp";
    return new Promise<any>((resolve, reject) => {
      
      this.context.commit(Mutations.SET_OTP_LOADING, true);
      ApiService.post(this.customerUrl.updateCustomerPhone, payload)
        .then(({ data }) => {
          
          this.context.commit(Mutations.SET_OTP_LOADING, false);
          this.context.commit(Mutations.SET_OTP_RESPONSE, data.data.response);
          
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_OTP_LOADING, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.UPDATE_CUSTOMER_OTHER_CONTACT_INFO](payload) {

    this.customerUrl.updateOtherContactInfo = "/skye/customer/update-customer-info";
    return new Promise<any>((resolve, reject) => {
      
      ApiService.post(this.customerUrl.updateOtherContactInfo, payload)
        .then(({ data }) => {
          notif.simple('Other Contact Info Updated', 'success', 'Other contact info updated successfully!');
          
          resolve(data);
        })
        .catch((error ) => {
          
          if(error.response.status === 422)
          {
              // let data = '<ul>';

              // Object.entries(error.response.data.data).forEach(element => {
              //     data += '<li style="color:orange;">' + element[1] + '</li>'
              // });
              
              // data += '</ul>';

              // notif.simple(error.response.data.message, 'warning', data, 0)
              // this.context.commit(Mutations.SET_PHONE_IS_ERROR, true)
          }
          //notif.simple("Please Note", 'warning', error.response.data.message, 0)
          reject(error);
        });
    });
  }

  @Mutation
  [Mutations.SET_UPLOAD_TAB_LEAD_ID](payload) {
    this.uploadTabLeadId = payload
  }

  @Mutation
  [Mutations.SET_OTP_LOADING](payload) {
    this.otp_loading = payload
  }

  @Mutation
  [Mutations.SET_OTP_TOKEN](payload) {
    this.otp_token = payload
  }

  @Mutation
  [Mutations.SET_OTP_RESPONSE](payload) {
    this.otp_response = payload
  }
  
  @Mutation
  [Mutations.SET_CUSTOMER_SEARCH_RESULT](payload): void {
    this.customerSearchList = payload;
  }
  @Mutation
  [Mutations.SET_CUSTOMER_STATUS](payload): void {
    this.customerStatusLogList = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_ACTIVITIES](payload): void {
    this.customerActivityLogList = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_VIEW_ACTIVITIES](payload): void {
    this.customerViewActivityLogList = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMER](payload): void {
    this.customers = payload.data.customers.data;
    this.paginationObj.links = payload.data.customers.links;
    this.paginationObj.first_page_url = payload.data.customers.first_page_url;
    this.paginationObj.last_page_url = payload.data.customers.last_page_url;
    this.paginationObj.from = payload.data.customers.from;
    this.paginationObj.to = payload.data.customers.to;
    this.paginationObj.next_page_url = payload.data.customers.next_page_url;
    this.paginationObj.prev_page_url = payload.data.customers.prev_page_url;
    this.paginationObj.total = payload.data.customers.total || 0;

  }
  @Mutation
  [Mutations.SET_CUSTOMER_DETAILS](payload): void {
    if (payload.data.customer.length > 0) {
      this.customerDetails = payload.data.customer[0];
    } else {
      this.customerDetails = payload.data.customer;
    }

  }
  @Mutation
  [Mutations.SET_LEAD_DETAILS](payload): void {
    this.leadDetails = payload.data.leads;
  }
  // @Mutation
  // [Mutations.SET_CUSTOMER_STATUS_COUNT](payload): void {
  //   this.statusCount = payload.data.status;
  //   this.statusCount.hasData = true;
  // }
  @Mutation
  [Mutations.SET_LEAD_STATUS_COUNT_TOOLBAR](payload): void {
    this.leadStatusCount = payload.data.status;
    this.leadStatusCount.hasData = true;
  }
  @Mutation
  [Mutations.SET_LEAD_STATUS_FILTER_COUNT](payload): void {
    this.statusCount = payload.data.status;
    this.statusCount.hasData = true;
  }
  @Mutation
  [Mutations.SET_SALES_AGENT_LIST](payload): void {
    console.log(payload);
    this.salesAgents = payload.data.agents;
    this.teamMembers = payload.data.team_members;
  }
  @Mutation
  [Mutations.SET_TASK_DUE_DATE_COUNT](payload): void {
    this.taskDueDateCount = payload.data.count;
    this.taskDueDateCount.hasData = true;
  }
  @Mutation
  [Mutations.SET_FILTERED_TASK_DUE_DATE_COUNT](payload): void {
    this.taskFilteredDueDateCount = payload.data.count;
    this.taskFilteredDueDateCount.hasData = true;
  }
  @Mutation
  [Mutations.SET_CALENDAR_EVENT](payload): void {
    this.eventDetails = payload.data.tasks;
  }
  @Mutation
  [Mutations.UPDATE_EDIT_CUSTOMER_EMAIL](payload): void {
    
    this.customerDetails = payload.customer;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    const errors = [] as any;
    for (const key in error) {

      errors.push(error[key][0]);

    }
    this.errors = errors;
  }

  @Mutation
  [Mutations.SET_OTP_ERROR](payload) {
    this.otp_error = payload
  }

  @Mutation
  [Mutations.SET_EXIST_CUSTOMER](payload) {
    if(typeof payload === 'object') {
      this.existCustomer = payload;
      this.isQLDisable = true
    } else {
      this.existCustomer = null;
      this.isQLDisable = false
    }
  }

  @Mutation
  [Mutations.SET_LEAD_LOADING](payload) {
    this.leadLoading = payload
  }

  @Mutation
  [Mutations.SET_QUICK_LEAD_ERROR](error) {
    
    const errors = [] as any;
    for (const key in error) {

      errors.push(error[key]);

    }
    this.QLerrors = errors;
  }

  @Mutation
  [Mutations.SET_UPDATE_PHONE_MODAL](payload) {
    this.phoneNumberModal = payload
  }

  @Mutation
  [Mutations.SET_QL_STATUS](payload) {
    this.qlEmail = payload
  }

  @Mutation
  [Mutations.SET_EMAIL_ADD_STATUS](payload) {
    this.addCustomerEmail = payload
  }

  @Mutation
  [Mutations.SET_EMAIL_UPDATE_STATUS](payload) {
    this.updateEmail = payload
  }

  @Mutation
  [Mutations.SET_EMAIL_IS_ERROR](payload) {
    this.isEmailError = payload
  }

  @Mutation
  [Mutations.SET_PHONE_IS_ERROR](payload) {
    this.isPhoneError = payload
  }

  @Mutation
  [Mutations.SET_QL_ENABLE](payload) {
    this.isQLDisable = payload
  }

  get getCustomers(): ICustomers {
    return this.customers;
  }
  get getEventDetails(): IEvents {
    return this.eventDetails;
  }
  get getLeadDetails(): ILeads {
    return this.leadDetails;
  }
  get getSalesAgentList(): ISalesAgent {
    return this.salesAgents;
  }
  get getTeamMemberList(): ISalesAgent {
    return this.teamMembers;
  }
  get getErrors(): Array<string> {
    return this.errors;
  }
  get getQuickLeadErrors(): Array<string> {
    return this.QLerrors;
  }
  get getCustomerErrors(): Array<string> {
    return this.errors;
  }
  get getSelectedCustomerDetails(): ICustomers{
    return this.customerDetails;
  }
  get getCustomerActivityLogList(){
    return this.customerActivityLogList;
  }
  get getCustomerViewActivityLogList(){
    return this.customerViewActivityLogList;
  }
  get getStatusCount(){
    return this.statusCount;
  }
  get getLeadStatusCount() {
    return this.leadStatusCount;
  }
  get getTaskDueDateCount(){
    return this.taskDueDateCount;
  }
  get getFilteredTaskDueDateCount(){
    return this.taskFilteredDueDateCount;
  }
  get getPaginationObject () {
    return this.paginationObj;
  }

  get getOtpLoading() {
    return this.otp_loading;
  }

  get getOtpToken() {
    return this.otp_token;
  }

  get getOTPResponse() {
    return this.otp_response;
  }

  get getOTPError() {
    return this.otp_error;
  }

  get getExistCustomer() {
    return this.existCustomer;
  }

  get getLeadLoading() {
    return this.leadLoading;
  }

  get uploadLeadIdTab() {
    return this.uploadTabLeadId;
  }

  get getUpdatePhoneModal() {
    return this.phoneNumberModal;
  }

  get getQlEmail() {
    return this.qlEmail;
  }

  get getAddCustomerEmail() {
    return this.addCustomerEmail;
  }

  get getUpdateEmail() {
    return this.updateEmail;
  }

  get getIsEmailError() {
    return this.isEmailError;
  }

  get getIsPhoneError() {
    return this.isPhoneError;
  }

  get getIsQLDisable() {
    return this.isQLDisable
  }

  get getDuplicateDriverErrorMessage(): IDuplicateData {
    return this.duplicateData;
  }

}
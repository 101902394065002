import { useRoute } from "vue-router"
import { useStore } from "vuex"
import { ref, watchEffect } from "vue"
import { setBreadCrumbs, setFromBreadCrumbs } from '@/store/composable/BreadCrumbs'
import Policy from "@/core/services/car/NEW/Policy"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useUpdateCustomerData } from '@/store/composable/Customer'

export function usePolicyDetails(policyId = null) {
    const route = useRoute()
    const store = useStore()
    const policy_id = policyId ? policyId : route.params.policyId  

    watchEffect(async() => {
        await Policy.getPolicyDetails({ policy_id })
        const policy = await store.getters.getPolicy
        let breadcrumb = setBreadCrumbs(policy.item.title)
        let fromBreadcrumb = setFromBreadCrumbs(policy.item.title)
        setCurrentPageBreadcrumbs(breadcrumb, fromBreadcrumb)
        const data =  await preload({store, policy_id, lead_id: policy.item.lead_id})
        store.commit('SET_POLICY_PRELOADED', data)
    })

}

export async function useUploadAmendDocument(payload) {
    return await Policy.uploadAmendDocument(payload)
}

export async function usePplicyDetails({ policy_id }) {
    return await Policy.getPolicyDetails({ policy_id })
}

export async function useCompletedApi({ policy_id, lead_id }) {
    const store = useStore()

    await Policy.getPolicyDetails({ policy_id })
    const compDocuments =  await useUpdateCustomerData({ 
        linkType: 'completed-documents',
        policy_id
    })
    store.commit('SET_POLICY_PRELOADED_COMP_DOCUMENTS', compDocuments.data.data.documents)
    
    const temp = await useUpdateCustomerData({
        lead_id,
        resetTempDoc: true,
        categories: 'standard',
        linkType: 'temp-documents',
    })
    store.commit('SET_POLICY_PRELOADED_TEMP_DOCUMENTS', temp.data.data.documents)

    
}

export const error = ref('')
export function useUpdateError(value) {
    error.value = value
}

async function preload ({ store, policy_id, lead_id }) {

    let activities = []
    let acceptedAmount = 0
    let pastDate = 0
    let futureDate = 0

    const compDocuments =  await useUpdateCustomerData({ 
        linkType: 'completed-documents',
        policy_id
    })
    store.commit('SET_POLICY_PRELOADED_COMP_DOCUMENTS', compDocuments.data.data.documents)

    const docTypes = await useUpdateCustomerData({
        categories: 'standard',
        linkType: 'document-types',
    })
    store.commit('SET_POLICY_PRELOADED_DOCUMENT_TYPES', docTypes.data.data.types)
    
    const temp = await useUpdateCustomerData({
        lead_id,
        resetTempDoc: true,
        categories: 'standard',
        linkType: 'temp-documents',
    })
    store.commit('SET_POLICY_PRELOADED_TEMP_DOCUMENTS', temp.data.data.documents)
    
    const amDocuments =  await useUpdateCustomerData({ 
        linkType: 'amend-documents',
        policy_id
    })
    store.commit('SET_POLICY_PRELOADED_AM_DOCUMENTS', amDocuments.data.data.amend_documents)

    const accepted =  await useUpdateCustomerData({ 
        linkType: 'get-policy-amount-accepted'
     })
    const emailActivities =  await useUpdateCustomerData({ 
        linkType: 'email-activity',
        policy_id
     })
     store.commit('SET_POLICY_PRELOADED_EMAIL_TYPES', emailActivities.data.data.activites)
    const restrict =  await useUpdateCustomerData({ 
        linkType: 'policy-restrict-dates'
     })
     
    acceptedAmount = parseInt(accepted.data.data.value)
    pastDate = parseInt(restrict.data.data.setting.value)
    futureDate = parseInt(restrict.data.data.upcoming.value)

    return {
        acceptedAmount,
        activities,
        pastDate,
        futureDate,
    }
}
export const policies_lists = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isBadge: true,
        isFixed: true,
        width: 180
    },
    // {
    //     label: 'Status',
    //     value: 'status_text',
    //     width: 150
    // },
    {
        label: 'Email<br />Mobile Number',
        value: 'email_mobile',
        isPopover: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Policy Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Policy Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Price(VAT)'],
        values: ['quote_ref_no', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Price'],
        values_without_vat: ['quote_ref_no', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    // {
    //     label: 'Discrepancy <br />Amount',
    //     value: 'discrepancy_amount',
    //     width: 120
    // },
    {
        label: 'Transaction Type /<br /> Code',
        value: 'transaction_type_text',
        width: 180
    },
    {
        label: 'Policy Type &amp;<br /> Coverage',
        value: 'policy_type',
        headerTitle: 'Policy Details',
        headers: ['Policy Type', 'Coverage'],
        values: ['insurance_cover_for', 'insurance_coverage_name'],
        width: 180
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 250
    },
    {
        label: 'Created On & <br />Issued/Completed',
        value: 'created_on',
        width: 180
    },
    {
        label: 'Start Date',
        value: 'policy_start_date',
        width: 150
    },
    {
        label: 'End Date',
        value: 'policy_end_date',
        width: 150
    },
    {
        label: 'Destination',
        value: 'destination_country',
        isPopover: true,
        headerTitle: 'Destinations',
        headers: [''],
        values: ['destination_country'],
        width: 150
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    // {
    //     label: 'Car Value<br />(CURRENCY)',
    //     value: 'car_value',
    //     width: 100
    // },
    // {
    //     label: 'Insurance Payment Type',
    //     value: 'insurance_payment_text',
    //     width: 150
    // },
]

export const all_documents = [
    {
        label: 'Preview',
        value: 'file_path',
        isPreview: true,
        width: 100
    },
    {
        label: 'Document Type',
        value: 'title',
        isTextSuccess: true
    },
    {
        label: 'File Name',
        value: 'original_filename',
        isWrap: true,
    },
    {
        label: 'Quote Ref. No.',
        value: 'quote_ref_no',
        width: 220
    },
    {
        label: 'Uploaded By',
        value: 'uploaded_by'
    },
]

export const amended_documents = [
    {
        label: '',
        value: 'file_path',
        isPreview: true,
        width: 100
    },
    {
        label: 'Document Type',
        value: 'title',
        isTextSuccess: true,
        width: 250
    },
    {
        label: 'File Name',
        value: 'original_filename',
        isWrap: true,
        width: 180
    },
    {
        label: 'Amended Quote Ref. No.',
        value: 'quote_ref_no',
        width: 220
    },
    {
        label: 'Uploaded By',
        value: 'uploaded_by'
    },
]

export const document_logs = [
    {
        label: 'Action',
        value: 'action'
    },
    {
        label: 'By',
        value: 'by'
    },
    {
        label: 'At',
        value: 'at'
    },
]

export const email_lists = [
    {
        label: 'Subject',
        value: 'subject',
        width: '450',

    },
    {
        label: 'Sent By',
        value: 'sent_by',
        width: '350'
    },
    {
        label: 'Sent On',
        value: 'created_at',
        width: ''
    },
    {
        label: 'Delivered',
        value: 'delivered',
        width: '',
        isClick: true,
        align: 'center'
    },
    {
        label: 'Clicked',
        value: 'clicked',
        width: '',
        isClick: true,
        align: 'center'
    },
    {
        label: 'View',
        value: 'email_body',
        isView: true,
        width: '150'
    },
]

export const quotes_details = [
    {
        label: 'Car Details',
        value: 'car_details',
        isMarkFlow: true
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: '150'
    },
    {
        label: 'Generate On',
        value: 'generate_on',
        width: '150'
    },
    {
        label: 'Generate By',
        value: 'generate_by',
        isMarkFlow: true,
        width: '150'
    },
    {
        label: 'Companies',
        value: 'companies'
    },
    {
        label: 'Payment Link',
        value: 'payment_link'
    },
]

export const sms = [
    {
        label: 'SMS Content',
        value: 'sms_content'
    },
    {
        label: 'Created on',
        value: 'created_at'
    },
    {
        label: 'Status',
        value: 'status'
    },
]

export const email_activity = [
    {
        label: 'Activity',
        value: 'activity'
    },
    {
        label: 'Sent By',
        value: 'sent_by'
    },
    {
        label: 'Send On',
        value: 'sent_on'
    },
]


export const leads_tasks = [
    {
        label: 'Name',
        value: 'name',
        isRoute: true,
        isWrap: true,
        isLeads: true,
        to: 'customer-details'
    },
    {
        label: 'Email <br />Mobile Number',
        value: 'email_mobile',
        first: 'email',
        second: 'complete_phone_number',
        isCopy: true
    },
    {
        label: 'User Type',
        value: 'customer_status_text',
        width: '280',
        isRoute: true,
        isMixin: true,
        to: 'customer-details'
    },
    {
        label: 'Is Renewal',
        value: 'is_renewal',
        width: '120',
        cn: 'text-center',
        align: 'center'
    },
    {
        label: 'Task #',
        value: 'customer_open_tasks_count',
        width: '80',
        align: 'center'
    },
    {
        label: 'Task Due Date',
        value: 'task_due_date',
        width: '170',
        isTimeLine: true,
        cni: 'task-due-date'
    },
    {
        label: 'Sales Agent',
        value: 'agent',
        withMultipleAgents: true,
        agents: 'agents',
        agentName: 'agentNameToString',
        width: '180',
        isRoute: true,
        withCn: true,
        to: 'customer-details'
    },
    {
        label: 'Select to Transfer',
        value: 'customer_id',
        width: '120',
        align: 'center',
        isTransfer: true,
        isDisabled: 'disabled_transfer'
    },
]

export const policy_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        width: 180
    },
    // {
    //     label: 'Status',
    //     value: 'status_text',
    //     width: 150
    // },
    {
        label: 'Email',
        value: 'email',
        isPopover: true,
        copyValue: true,
        first: 'email',
        isCopy: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Policy Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Policy Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Invoice Price(VAT)'],
        values: ['quote_ref_no', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Invoice Price'],
        values_without_vat: ['quote_ref_no', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    // {
    //     label: 'Discrepancy <br />Amount',
    //     value: 'discrepancy_amount',
    //     width: 120
    // },
    {
        label: 'Transaction Type /<br /> Code',
        value: 'transaction_type_text',
        isPopover: true,
        headers: [''],
        values: ['online_link'],
        width: 180
    },
    {
        label: 'Policy Type &amp;<br /> Coverage',
        isPopoverWrap: true,
        value: 'policy_type',
        headerTitle: 'Policy Details',
        headers: ['Policy Type', 'Coverage'],
        values: ['insurance_cover_for', 'insurance_coverage_name'],
        width: 180
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 150
    },
    {
        label: 'Created On & <br />Issued/Completed',
        value: 'created_on',
        width: 180
    },
    {
        label: 'Created &<br />Approved By',
        value: 'cpb',
        width: 180
    },
    // {
    //     label: 'Policy<br />Created On',
    //     value: 'created_on',
    //     is_policy_assigned: true,
    //     width: 180
    // },
    {
        label: 'Start Date <br /> End Date',
        value: 'policy_start_end_date',
        width: 150
    },
    // {
    //     label: 'Start Date',
    //     value: 'policy_start_date',
    //     width: 150
    // },
    // {
    //     label: 'End Date',
    //     value: 'policy_end_date',
    //     width: 150
    // },
    {
        label: 'Destination',
        value: 'destination_country',
        isPopoverWrap: true,
        headerTitle: 'Destinations',
        headers: [''],
        values: ['destination_country'],
        width: 150
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    // {
    //     label: 'Car Value<br />(CURRENCY)',
    //     value: 'car_value',
    //     width: 100
    // },
    // {
    //     label: 'Insurance Payment Type',
    //     value: 'insurance_payment_text',
    //     width: 150
    // },
]


export const policy_cancel_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        width: 180
    },
    {
        label: 'Lead Details',
        value: 'details',
        isWrap: true,
        width: 250
    },
    {
        label: 'Policy Status',
        value: 'status_text',
        width: 250
    },
    {
        label: 'Policy Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Policy Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Cancellation Type',
        value: 'cancellation_type_text',
        width: 150
    },
    {
        label: 'Cancellation Reason',
        value: 'cancellation_reason_text',
        width: 150
    },
    {
        label: 'Payable<br />Amount (CURRENCY)',
        value: 'cancellation_payable_amount',
        width: 120
    },
    {
        label: 'Credit Note (CURRENCY)',
        value: 'cancellation_credit_amount',
        width: 120
    },
    {
        label: 'CN No.',
        value: 'cancellation_credit_notes_no',
        width: 120
    },
    {
        label: 'Debit Note (CURRENCY)',
        value: 'cancellation_debit_amount',
        width: 120
    },
    {
        label: 'DN No.',
        value: 'cancellation_debit_notes_no',
        width: 120
    },
    {
        label: 'Endorsement No.',
        value: 'cancellation_endorsement_no',
        width: 150
    },
    {
        label: 'Transaction <br />Type',
        value: 'transaction_type_text',
        isPopover: true,
        headers: [''],
        values: ['online_link'],
        width: 120
    },
    {
        label: 'Insurance Payment Type',
        value: 'insurance_payment_text',
        width: 150
    },
    
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Cancellation<br />Requested On',
        value: 'cancellation_request_date',
        width: 150
    },
    
]

export const policy_refund_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        width: 180
    },
    {
        label: 'Lead Details',
        value: 'details',
        isWrap: true,
        width: 250
    },
    {
        label: 'Status',
        value: 'status_text',
        width: 150
    },
    {
        label: 'Policy Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Policy Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Invoice Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Refund Type',
        value: 'refund_type_text',
        width: 200
    },
    {
        label: 'Refund Reason',
        value: 'refund_reasons_text',
        width: 200
    },
    {
        label: 'Payable Amount<br />(CURRENCY)',
        value: 'refund_payable_amount_formated',
        width: 200
    },
    {
        label: 'Transaction <br />Type',
        value: 'transaction_type_text',
        isPopover: true,
        headers: [''],
        values: ['online_link'],
        width: 120
    },
    {
        label: 'Invoice Amount<br />VAT(CURRENCY)',
        value: 'invoice_amount_break',
        is_policy_completed: true,
        width: 180
    },
    
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    
    {
        label: 'Refund<br />Requested On',
        value: 'refund_created_at',
        width: 200
    },
]

export const expired_leads_report_headers = [ 
    {
        label: 'SR.#',
        value: 'sr_id',
        isFixed:true,
        width: 80
    },
    {
        label: 'Lead Created On',
        value: 'created_at',
        isFixed:true,
        width: 180
    },
    {
        label: 'Lead Assigned To',
        value: 'el_agent_name',
        isFixed:false,
        width: 180
    },
    {
        label: 'Lead Status',
        value: 'lead_status_text',
        isFixed:false,
        width: 150
    },
    {
        label: 'Lead Expired On',
        value: 'deleted_at',
        isFixed:false,
        width: 180
    },
    {
        label: 'Name',
        value: 'el_customer_name',
        isFixed:false,
        width: 180
    },
    {
        label: 'Email',
        value: 'el_customer_email',
        isFixed:false,
        width: 300
    },
    {
        label: 'Phone Number',
        value: 'el_customer_phone_number',
        isFixed:false,
        width: 180
    },
    {
        label: 'Lead Details',
        value: 'el_lead_details',
        isFixed:false,
        width: 200
    },
    {
        label: 'Lead Source',
        value: 'el_lead_source',
        isFixed:false,
        width: 150,
    },
    {
        label: 'UTM Source',
        value: 'el_utm_source',
        isFixed:false,
        width: 150
    },
    {
        label: 'UTM Medium',
        value: 'el_utm_medium',
        isFixed:false,
        width: 150
    },
    {
        label: 'UTM Campaign',
        value: 'el_utm_campaign',
        isFixed:false,
        width: 200
    },
    {
        label: 'UTM Content',
        value: 'el_utm_content',
        isFixed:false,
        width: 200
    },
    {
        label: 'Other Lead Source',
        value: 'el_other_source',
        isFixed:false,
        width: 250,
    },
    {
        label: 'Other UTM Source',
        value: 'el_other_utm_source',
        isFixed:false,
        width: 250
    },
    {
        label: 'Other UTM Medium',
        value: 'el_other_utm_medium',
        isFixed:false,
        width: 250
    },
    {
        label: 'Other UTM Campaign',
        value: 'el_other_utm_campaign',
        isFixed:false,
        width: 200
    },
    {
        label: 'Other UTM Content',
        value: 'el_other_utm_content',
        isFixed:false,
        width: 200
    }
]

export const lead_by_source_report_headers = [ 
    {
        label: 'SR. #',
        value: 'sr_id',
        isFixed:true,
        width: 80
    },
    {
        label: 'Name',
        value: 'el_customer_name',
        isFixed:true,
        width: 180
    },
    {
        label: 'Email',
        value: 'el_customer_email',
        isFixed:true,
        width: 300
    },
    {
        label: 'Phone Number',
        value: 'el_customer_phone_number',
        isFixed:false,
        width: 180
    },
    {
        label: 'Lead Status',
        value: 'lead_status_text',
        isFixed:false,
        width: 150
    },
    {
        label: 'Sales Agent',
        value: 'el_agent_name',
        isFixed:false,
        width: 180
    },
    {
        label: 'Lead Source',
        value: 'el_lead_source',
        isFixed:false,
        width: 150,
    },
    {
        label: 'UTM Source',
        value: 'el_utm_source',
        isFixed:false,
        width: 150
    },
    {
        label: 'UTM Medium',
        value: 'el_utm_medium',
        isFixed:false,
        width: 150
    },
    {
        label: 'UTM Campaign',
        value: 'el_utm_campaign',
        isFixed:false,
        width: 200
    },
    {
        label: 'UTM Content',
        value: 'el_utm_content',
        isFixed:false,
        width: 200
    },
    {
        label: 'Other Lead Source',
        value: 'el_other_source',
        isFixed:false,
        width: 250,
    },
    {
        label: 'Other UTM Source',
        value: 'el_other_utm_source',
        isFixed:false,
        width: 250
    },
    {
        label: 'Other UTM Medium',
        value: 'el_other_utm_medium',
        isFixed:false,
        width: 250
    },
    {
        label: 'Other UTM Campaign',
        value: 'el_other_utm_campaign',
        isFixed:false,
        width: 200
    },
    {
        label: 'Other UTM Content',
        value: 'el_other_utm_content',
        isFixed:false,
        width: 200
    },
    {
        label: 'Lead Details',
        value: 'el_lead_details',
        isFixed:false,
        width: 200
    },
    {
        label: 'Policy Price <br/>(CURRENCY)',
        value: 'el_policy_price',
        isFixed:false,
        width: 150
    },
    {
        label: 'Created On',
        value: 'created_at',
        isFixed:false,
        width: 180
    },
    {
        label: 'Updated On',
        value: 'updated_at',
        isFixed:false,
        width: 180
    },
    
]

export const dashboard_details_report_headers = [ 
    {
        label: 'SR. #',
        value: 'sr_id',
        isFixed:true,
        width: 80
    },
    {
        label: 'Name',
        value: 'el_customer_name',
        isFixed:true,
        width: 180
    },
    {
        label: 'Email',
        value: 'el_customer_email',
        isFixed:true,
        width: 300
    },
    {
        label: 'Phone Number',
        value: 'el_customer_phone_number',
        isFixed:false,
        width: 180
    },
    {
        label: 'Lead Status',
        value: 'lead_status_text',
        isFixed:false,
        width: 150
    },
    {
        label: 'Sales Agent',
        value: 'el_agent_name',
        isFixed:false,
        width: 180
    },
    {
        label: 'Lead Source',
        value: 'el_lead_source',
        isFixed:false,
        width: 150,
    },
    {
        label: 'UTM Source',
        value: 'el_utm_source',
        isFixed:false,
        width: 150
    },
    {
        label: 'UTM Medium',
        value: 'el_utm_medium',
        isFixed:false,
        width: 150
    },
    {
        label: 'UTM Campaign',
        value: 'el_utm_campaign',
        isFixed:false,
        width: 200
    },
    {
        label: 'UTM Content',
        value: 'el_utm_content',
        isFixed:false,
        width: 200
    },
    {
        label: 'Other Lead Source',
        value: 'el_other_source',
        isFixed:false,
        width: 250,
    },
    {
        label: 'Other UTM Source',
        value: 'el_other_utm_source',
        isFixed:false,
        width: 250
    },
    {
        label: 'Other UTM Medium',
        value: 'el_other_utm_medium',
        isFixed:false,
        width: 250
    },
    {
        label: 'Other UTM Campaign',
        value: 'el_other_utm_campaign',
        isFixed:false,
        width: 200
    },
    {
        label: 'Other UTM Content',
        value: 'el_other_utm_content',
        isFixed:false,
        width: 200
    },
    {
        label: 'Lead Details',
        value: 'el_lead_details',
        isFixed:false,
        width: 200
    },
    {
        label: 'Policy Price <br/>(CURRENCY)',
        value: 'el_policy_price',
        isFixed:false,
        width: 150
    },
    {
        label: 'Created On',
        value: 'created_at',
        isFixed:false,
        width: 180
    },
    {
        label: 'Updated On',
        value: 'updated_at',
        isFixed:false,
        width: 180
    },
    
]

export const dashboard_sales_person_info = [ 
    {
        label: 'Name',
        value: 'name',
        isFixed:true,
        width: 180
    },
    {
        label: 'New Leads',
        value: 'new_leads',
        isFixed:false,
        width: 120
    },
    {
        label: 'New Deals',
        value: 'new_deals',
        isFixed:false,
        width: 120
    },
    {
        label: 'CR (New)',
        value: 'new_closing_ratio',
        isFixed:false,
        width: 120
    },
    {
        label: 'WIP Deals',
        value: 'wip_deals',
        isFixed:false,
        width: 120
    },
    {
        label: 'Total Leads',
        value: 'total_leads',
        isFixed:false,
        width: 120
    },
    {
        label: 'Total Deals',
        value: 'total_deals',
        isFixed:false,
        width: 120
    },
    {
        label: 'CR (Total)',
        value: 'total_closing_ratio',
        isFixed:false,
        width: 120
    },
    {
        label: 'Total </br>Revenue (CURRENCY)',
        value: 'total_revenue',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total % Met',
        value: 'target_percentage_met',
        isFixed:false,
        width: 120
    },
    {
        label: 'Expired Leads',
        value: 'expired_leads',
        isFixed:false,
        width: 120
    },
    {
        label: 'Lost Leads',
        value: 'lost_leads',
        isFixed:false,
        width: 120
    },
    {
        label: 'Untouched Customers',
        value: 'untouched_customers',
        isFixed:false,
        width: 120
    },
    {
        label: 'Untouched Leads',
        value: 'untouched_leads',
        isFixed:false,
        width: 120
    },
    {
        label: 'Over Due Tasks',
        value: 'over_due_tasks',
        isFixed:false,
        width: 120
    },
    {
        label: 'Pending Leads',
        value: 'pending_leads',
        isFixed:false,
        width: 120
    }
]

export const dashboard_statistics_top_providers = [ 
    {
        label: 'Provider Name',
        value: 'name',
        isFixed:false,
        width: 300
    },
    {
        label: 'Total Policies Sold',
        value: 'policies_sold',
        isFixed:false,
        width: 250
    },
    {
        label: 'Total Revenue (CURRENCY)',
        value: 'total_revenue',
        isFixed:false,
        width: 268
    },
]

export const dashboard_statistics_top_nationalities = [ 
    {
        label: 'Country Name',
        value: 'name',
        isFixed:false,
        width: 300
    },
    {
        label: 'Total Policies Sold',
        value: 'policies_sold',
        isFixed:false,
        width: 250
    },
    {
        label: 'Total Revenue (CURRENCY)',
        value: 'total_revenue',
        isFixed:false,
        width: 268
    },
]

export const dashboard_others_misk_leads = [ 
    {
        label: 'Name',
        value: 'el_customer_name',
        isFixed:false,
        width: 215
    },
    {
        label: 'Email',
        value: 'el_customer_email',
        isFixed:false,
        width: 300
    },
    {
        label: 'Phone Number',
        value: 'el_customer_phone_number',
        isFixed:false,
        width: 150
    },
    {
        label: 'Lead Details',
        value: 'el_lead_details',
        isFixed:false,
        width: 150
    },
]

export const dashboard_others_transactions = [ 
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed:false,
        width: 215
    },
    {
        label: 'Policy Name',
        value: 'insurance_plan_name',
        isFixed:false,
        width: 400
    },
    {
        label: 'Policy Price (CURRENCY)',
        value: 'el_policy_price',
        isFixed:false,
        width: 200
    }
]

export const account_master_report_headers = [ 
    {
        label: 'SR. #',
        value: 'sr_id',
        isFixed:true,
        width: 80
    },
    {
        label: 'Quote Ref No.',
        value: 'quote_ref_no',
        isFixed:true,
        width: 180
    },
    {
        label: 'Invoice <br/>Approved On',
        value: 'approved_date',
        isFixed:false,
        width: 160
    },
    {
        label: 'Transaction <br/>Type',
        value: 'payment_type_text',
        isFixed:false,
        width: 120
    },
    {
        label: 'Payment Date',
        value: 'payment_date',
        isFixed:false,
        width: 150
    },
    {
        label: 'Customer Name',
        value: 'el_customer_name',
        isFixed:false,
        width: 180
    },
    {
        label: 'Policy <br/>Created On',
        value: 'el_policy_created_on',
        isFixed:false,
        width: 160
    },
    {
        label: 'Policy <br/>Completed On',
        value: 'el_policy_completed_on',
        isFixed:false,
        width: 160
    },
    {
        label: 'Policy Status',
        value: 'el_policy_status_text',
        isFixed:false,
        width: 150
    },
    {
        label: 'Policy <br/>Start Date',
        value: 'el_policy_start_date',
        isFixed:false,
        width: 150
    },
    {
        label: 'Policy <br/>End Date',
        value: 'el_policy_end_date',
        isFixed:false,
        width: 150
    },
    {
        label: 'Policy Number',
        value: 'el_policy_insurance_policy_number',
        isFixed:false,
        width: 180
    },
    {
        label: 'Company Name',
        value: 'el_provider_name',
        isFixed:false,
        width: 150
    },
    {
        label: 'Plan Name',
        value: 'insurance_plan_name',
        isFixed:false,
        width: 180
    },
    {
        label: 'Policy Type',
        value: 'insurance_cover_for',
        isFixed:false,
        width: 150
    },
    {
        label: 'Policy Price <br/>(CURRENCY)',
        value: 'total_price_vat',
        isFixed:false,
        width: 120
    },
    {
        label: 'Policy Price <br/>VAT (CURRENCY)',
        value: 'vat',
        isFixed:false,
        width: 120
    },
    {
        label: 'Lead Details',
        value: 'el_lead_details',
        isFixed:false,
        width: 180
    },
    {
        label: 'Lead Source',
        value: 'el_lead_source',
        isFixed:false,
        width: 150,
    },
    {
        label: 'Sales Agent',
        value: 'el_agent_name',
        isFixed:false,
        width: 180
    },
]

export const admin_report_headers = [ 
    {
        label: 'SR. #',
        value: 'sr_id',
        isFixed:true,
        width: 80
    },
    {
        label: 'Months-Year',
        value: 'y_month_format',
        isFixed:true,
        width: 150
    },
    {
        label: 'Total Sales',
        value: 'el_total_sales',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total Leads',
        value: 'el_total_leads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Closing <br/>Ratio (%)',
        value: 'el_total_closing_ratio',
        isFixed:false,
        width: 150
    },
    {
        label: 'Orient',
        value: 'el_total_orient',
        isFixed:false,
        width: 150
    },
    {
        label: 'ISA',
        value: 'el_total_isa',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total <br/>Revenue (CURRENCY)',
        value: 'el_total_revenue',
        isFixed:false,
        width: 150
    },
    {
        label: 'New Sales',
        value: 'el_new_sales',
        isFixed:false,
        width: 150
    },
    {
        label: 'New Leads',
        value: 'el_new_leads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Closing <br/>Ratio (%)',
        value: 'el_new_closing_ratio',
        isFixed:false,
        width: 150
    },
    {
        label: 'Orient',
        value: 'el_new_orient',
        isFixed:false,
        width: 150
    },
    {
        label: 'ISA',
        value: 'el_new_isa',
        isFixed:false,
        width: 150
    },
    {
        label: 'Revenue <br/>(CURRENCY)',
        value: 'el_new_revenue',
        isFixed:false,
        width: 150
    },
    {
        label: 'WIP Sales',
        value: 'el_wip_sales',
        isFixed:false,
        width: 150
    },
    {
        label: 'Orient',
        value: 'el_wip_orient',
        isFixed:false,
        width: 150
    },
    {
        label: 'ISA',
        value: 'el_wip_isa',
        isFixed:false,
        width: 150
    },
    {
        label: 'Revenue <br/>(CURRENCY)',
        value: 'el_wip_revenue',
        isFixed:false,
        width: 150
    },
    {
        label: 'Lost Leads',
        value: 'el_lost_leads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Expired Leads',
        value: 'el_expired_leads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Untouched <br/>Customers',
        value: 'el_untouched_customers',
        isFixed:false,
        width: 150
    },
    {
        label: 'Untouched <br/>Leads',
        value: 'el_untouched_new_leads',
        isFixed:false,
        width: 150
    }
]

export const customers_without_lead_report_headers = [ 
    {
        label: 'Customer ID',
        value: 'main_customer_id',
        isFixed:false,
        width: 120
    },
    {
        label: 'Customer Name',
        value: 'name',
        isFixed:false,
        width: 150
    },
    {
        label: 'Customer Email',
        value: 'email',
        isFixed:false,
        width: 150
    },
    {
        label: 'Customer Phone',
        value: 'el_customer_complete_phone_number',
        isFixed:false,
        width: 150
    },
    {
        label: 'Created On',
        value: 'created_at',
        isFixed:false,
        width: 150
    },
]

export const production_report_report_headers = [ 
    {
        label: 'SR #',
        value: 'sr_id',
        isFixed:true,
        width: 150
    },
    {
        label: 'From Date <br/>To Date',
        value: 'start_end_date',
        isFixed:false,
        width: 260
    },
    {
        label: 'Created On',
        value: 'created_at',
        isFixed:false,
        width: 260
    },
    {
        label: 'Requested By',
        value: 'requested_by',
        isFixed:false,
        width: 260
    },
    {
        label: 'Misk Type',
        value: 'misk_type_text',
        isFixed:false,
        width: 260
    }
]

import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import ApiService from "@/core/services/ApiService";
import INotificationList from "@/models/INotification";
import IPaginationInfo from "@/models/IPaginationInfo";
export interface NotificationInfo {
    notificationUrl: NotificationUrlInfo;
    notificationList: Array<INotificationList>;
    paginationObj: IPaginationInfo;
    recentNotificationUrl: NotificationUrlInfo;
    paginationAllObj: IPaginationInfo;
    notificationAllList: INotificationList;
}

export interface NotificationUrlInfo {
    getNotificationList: string;
    updateNotification: string;
    getAllNotificationList: string;
    updateNotificationClick: string;
}

export interface INotificationCount {
    count: any;
}
@Module
export default class NotificationModule extends VuexModule implements NotificationInfo {
    notificationUrl = {} as NotificationUrlInfo;
    notificationList = [] as Array<INotificationList>;
    paginationObj = {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: 1,
        total: 1,
    } as IPaginationInfo;
    notificationCount = {} as INotificationCount;
    recentNotificationUrl = {} as NotificationUrlInfo;
    paginationAllObj = {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: 1,
        total: 1,
    } as IPaginationInfo;
    notificationAllList = {} as INotificationList;
    // recentNotificationUrl = {} as NotificationUrlInfo;
    @Action
    [Actions.GET_NOTIFICATION_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            this.notificationUrl.getNotificationList = (payload.index == 1) ? "/misk-skye/notifications/list-recent-notifications" : "/misk-skye/notifications/list-recent-notifications?page=" + payload.index;
            // this.notificationUrl.getNotificationList = "/skye/notifications/list-recent-notifications";

            ApiService.post(this.notificationUrl.getNotificationList, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_NOTIFICATION_LIST, data.data.notifications);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_NOTIFICATION_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    @Action
    [Actions.MAKE_NOTIFICATION_READ]() {
        return new Promise<void>((resolve, reject) => {
            this.notificationUrl.updateNotification = "/misk-skye/notifications/update-notification";
            // this.notificationUrl.getNotificationList = "/skye/notifications/list-recent-notifications";

            ApiService.post(this.notificationUrl.updateNotification, {})
                .then(({ data }) => {
                    // this.context.commit(Mutations., data.data.notifications);
                    this.context.commit(Mutations.SET_NOTIFICATION_COUNT, data.data.count);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_NOTIFICATION_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_NOTIFICATION_COUNT]() {
        return new Promise<void>((resolve, reject) => {
            this.notificationUrl.updateNotification = "/misk-skye/notifications/count-unread-notification";
            // this.notificationUrl.getNotificationList = "/skye/notifications/list-recent-notifications";

            ApiService.post(this.notificationUrl.updateNotification, {})
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_NOTIFICATION_COUNT, data.data.count);
                    resolve(data);
                })
                .catch(({ response }) => {
                    if(ApiService.errUndefinedCheck(response)){
                        this.context.commit(Mutations.SET_NOTIFICATION_ERROR, response.data.errors);
                        reject(response);
                    }
                });
        });
    }
    @Action
    [Actions.GET_ALL_NOTIFICATION_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            this.notificationUrl.getAllNotificationList = (payload.index == 1) ? "/misk-skye/notifications/list-notifications" : "/misk-skye/notifications/list-notifications?page=" + payload.index;

            ApiService.post(this.notificationUrl.getAllNotificationList, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_ALL_NOTIFICATION_LIST, data.data.notifications);
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_NOTIFICATION_ERROR, response.data.errors);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.UPDATE_NOTIFICATION_CLICK](payload) {
        return new Promise<void>((resolve, reject) => {
            this.notificationUrl.updateNotificationClick = "/misk-skye/notifications/update-click-notification";

            ApiService.post(this.notificationUrl.updateNotificationClick, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }

    @Mutation
    [Mutations.SET_NOTIFICATION_LIST](payload) {
       
        this.notificationList = payload.data;
        this.paginationObj.links = payload.links;
        this.paginationObj.first_page_url = payload.first_page_url;
        this.paginationObj.last_page_url = payload.last_page_url;
        this.paginationObj.from = payload.from;
        this.paginationObj.to = payload.to;
        this.paginationObj.next_page_url = payload.next_page_url;
        this.paginationObj.prev_page_url = payload.prev_page_url;
        this.paginationObj.total = payload.total;
    }
    @Mutation
    [Mutations.SET_ALL_NOTIFICATION_LIST](payload) {
       
        this.notificationAllList = payload.data;
        this.paginationAllObj.links = payload.links;
        this.paginationAllObj.first_page_url = payload.first_page_url;
        this.paginationAllObj.last_page_url = payload.last_page_url;
        this.paginationAllObj.from = payload.from;
        this.paginationAllObj.to = payload.to;
        this.paginationAllObj.next_page_url = payload.next_page_url;
        this.paginationAllObj.prev_page_url = payload.prev_page_url;
        this.paginationAllObj.total = payload.total;
    }
    @Mutation
    [Mutations.SET_NOTIFICATION_COUNT](payload) {
       
        this.notificationCount = payload;

    }
    get getNotification(): Array<INotificationList> {
        return this.notificationList;
    }
    get getNotificationPaginationObj(): IPaginationInfo {
        return this.paginationObj;
    }
    get getUnreadNotificationCount(): INotificationCount {
        return this.notificationCount;
    }
    get getAllNotification(): INotificationList {
        return this.notificationAllList;
    }
    get getAllNotificationPaginationObj(): IPaginationInfo {
        return this.paginationAllObj;
    }
}

import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import ITaskList from "@/models/car/ITaskList";
import IErrors from "@/models/car/IErrors";
import ILeadDetails from "@/models/car/LeadDetails";
import ILeadDriverDetails from "@/models/car/LeadDriverDetails";
import axios from "axios";
import { notif } from '@/store/stateless/store';

export interface TaskInfo {
    tasksUrl:TaskUrlInfo;
    taskList:ITaskList;
    errors:IErrors;
    showTaskDom : any;
  }

export interface TaskUrlInfo{
  getLeadTaskList:string;
  deleteLeadTask:string;
  addLeadTask:string;
}





@Module
export default class TaskModule extends VuexModule implements  TaskInfo{

    tasksUrl = {} as TaskUrlInfo;
    taskList = {} as ITaskList;
    showTaskDom = true;
    errors = {} as IErrors;
    
    @Action
    [Actions.GET_LEAD_TASK_LIST](payload){// 
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_SHOW_TASK_DOM,false);
            this.tasksUrl.getLeadTaskList = "/skye/task/car-lead-tasks";
            ApiService.post(this.tasksUrl.getLeadTaskList,payload)
            .then(({data}) => { // 
                this.context.commit(Mutations.SET_LEAD_TASK_LIST,data.data.tasks);
                resolve(data);
                this.context.commit(Mutations.SET_SHOW_TASK_DOM,true);
            })
            .catch(({ response }) => { // 
                this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    @Action
    [Actions.DELETE_LEAD_TASK](payload){// 
        return new Promise<any>((resolve, reject) => {
            this.tasksUrl.deleteLeadTask = "/misk-skye/tasks/delete";
            ApiService.post(this.tasksUrl.deleteLeadTask,payload)
            .then(({data}) => { // 
                this.context.commit(Mutations.SET_LEAD_TASK_LIST,data.data.tasks);
                resolve(data);
            })
            .catch(({ response }) => { // 
                this.context.commit(Mutations.SET_QUOTES_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    
    @Action
    [Actions.ADD_LEAD_TASK](payload){ 
      this.tasksUrl.addLeadTask = "/skye/task/add-lead-task";
      return new Promise<any>((resolve, reject) => {
        ApiService.post(this.tasksUrl.addLeadTask,payload)
          .then(({ data }) => {    
            this.context.commit(Mutations.SET_ERROR, []);
            // this.context.commit(Mutations.SET_CALENDAR_EVENT, data);
            resolve(data);
          })
          .catch(({ response }) => {    
            // console.log(response.data.message)
            if(response.data.message == 'has-invoice') {
              notif.simple('Task Details', 'warning', 'This Lead has an existing active invoice, hence cannot be marked as false lead or redundant lead')
            }
            // this.context.commit(Mutations.SET_TASK_ERROR, response);
            reject();
          });
      });

    }
    @Mutation
    [Mutations.SET_TASK_ERROR](error) { 
        this.errors = error.data;
        // for (const key in error.data.data) {
        //     this.errors = error.data.data[key];
        //   }
        //   this.errors.lead_id = error.data.lead_id;
    }
    @Mutation
    [Mutations.SET_SHOW_TASK_DOM](payload) : void {
    //   this.notesList = payload;
      this.showTaskDom = payload;
    }

    @Mutation
    [Mutations.SET_LEAD_TASK_LIST](payload) : void {
      this.taskList = payload;
    }
    get getTaskDomStatus(): any {
        return  this.showTaskDom;
    }
    get getTaskErrors(): IErrors {
        return this.errors;
      }
    
    // get getCarLead(): ILeadDetails{
    //     return  this.carLead;
    // }
}

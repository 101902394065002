import { reactive } from "vue"

const constant = reactive({
    view_task: {
        open: false,
        lead_id: 0,
        lead_status_id: 0,
        customer_id: 0,
        agent: 0,
        is_fullscreen: false
    },

    upload_documents: {
        disabled: false,
        lead_id: 0
    },

})

export default constant